.bookshelf-wrapper {
  @media screen and (min-width: 750px) {
    max-width: 75%;
  }
}

.book {
  display: flex;
  margin-top: 3em;
}

.book__image {
  display: none;

  @media all and (min-width: 900px) {
    display: block;
    margin-right: 3em;
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
}

.book__data {
  flex: 1 0 60%;
}

.book__title {
  font-size: 1.6em;
  margin-top: 0;

  @media all and (min-width: 800px) {
    font-size: 2.5em;
  }

  line-height: 1.1;
  margin-bottom: 0;
}

.book__author {
  font-weight: 400;
  color: #aaa;
  margin-top: 0.5em;
}

.book__description {
  * {
    font-weight: 400;
  }

  overflow: hidden;
  max-height: 275px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), white 100%);
    .dark & {
      background: linear-gradient(to right, rgba(17, 17, 17, 0.1), rgba(17, 17, 17, 1) 50%);
    }
  }
}

.favourite-books {
  column-count: 1;

  @media all and (min-width: 800px) {
    column-count: 2;
    column-gap: 2em;
  }

  span {
    font-weight: bold;
    color: #333;

    .dark & {
      color: #eee;
    }
  }

  p {
    color: #aaa;
  }
}

.favourite-books__tag {
  font-size: 1rem;
  color: #333;
}
