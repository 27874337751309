@import '~normalize.css/normalize.css';
@import './base';
@import './media';
@import './fonts';
@import './navigation';
@import './lists';
@import './animations';
@import './bookshelf';

// custom styles

@import url('https://use.typekit.net/gum3gab.css');

$link-color: #ea215a;

a {
  color: $link-color;
  text-decoration-color: $link-color;
  border-bottom: 1px solid $link-color;
  transition: padding 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    padding-bottom: 2px;
  }
}

.ff-cmp {
  font-weight: 400;
  font-style: normal;
}

.page-home {
  margin: auto;
  display: grid;
  max-width: 80em;
  grid-gap: 1em;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    'header header header'
    'content content content'
    'bio bio bio'
    'details details details'
    'footer footer footer';

  @media all and (min-width: 800px) {
    grid-template-areas:
      'header header header'
      'content content .'
      'details bio bio'
      'footer footer footer';
  }
}

.page-full {
  grid-template-areas:
  'header header header'
  'content content content'
  'bio bio bio'
  'details details details'
  'footer footer footer';

  @media all and (min-width: 800px) {
    grid-template-areas:
      'header header header'
      'content content .'
      'bio bio bio'
      'footer footer footer';
  }
}

h1 {
  font-size: 5em;
  margin-bottom: 1rem;
}

.main-header {
  margin-top: 10rem;
  font-size: 3em;
  line-height: 0.8;

  @media all and (min-width: 800px) {
    font-size: 10em;
  }
}

.navigation {
  grid-area: nav;
}

main {
  grid-area: content;
}

header {
  grid-area: header;
}

.details {
  grid-area: details;
}

.intro-section {
  grid-area: bio;
}

.footer {
  grid-area: footer;
}

.intro-section {
  font-size: 1.3em;

  > .projects__title {
    margin-top: 0;
  }
}

.intro-text {
  font-size: 1.7em;
  font-weight: 500;

  @media screen and (min-width: 600px) {
    font-size: 2em;
  }
}

.info-block {
  margin-bottom: 2rem;
}

.info-block__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.info-block__text {
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

// Utils

.projects {
  margin-top: 2.5em;
  font-size: 0.9em;
}

.project-description {
  @media screen and (min-width: 600px) {
    width: 80%;
  }
}

.projects__title {
  font-size: 1.52em;
  margin-top: 1.5em;
}

footer {
  width: 100%;
  margin-top: 3em;
  position: relative;
  z-index: 1;
}

.footer__content {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 80em;
  margin: 0 auto;
  padding: 3em 0;
}

.footer__alternative {
  font-size: 80%;

  @media screen and (min-width: 600px) {
    max-width: 70%;
  }
}

// Pages

.js-bookshelf {
  display: none;
  opacity: 0;
}

.js-about {
  display: none;
  opacity: 0;
}
