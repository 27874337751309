// Animations
@keyframes slide-up {
  from {
    transform: translateY(0.5rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up-slower {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.delay-1 {
  animation-delay: 0.3s !important;
}

.delay-2 {
  animation-delay: 0.7s !important;
}

.slideUp {
  animation: slide-up 1s ease-in-out;
}

.slideUp--slower {
  animation: slide-up-slower 1.3s ease-in-out;
}

.hidePage {
  animation: hide-page 1s ease-in-out forwards;
}

.showPage {
  animation: show-page 1s ease-in-out forwards;
}

@keyframes hide-page {
  from {
    transform: translateY(0em);
    opacity: 1;
  }

  to {
    transform: translateY(0.5rem);
    opacity: 0;
  }
}

@keyframes show-page {
  from {
    transform: translateY(0em);
    opacity: 0;
  }

  to {
    transform: translateY(0.5rem);
    opacity: 1;
  }
}
