.navigation {
  z-index: 2;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: #ea215a;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03),
    0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.03);
}

.navigation ul {
  justify-content: center;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  font-size: 1rem;
  letter-spacing: 1px;
  align-content: center;

  @media screen and (min-width: 700px) {
    justify-content: flex-end;
  }

  li {
    // display: flex;
    align-items: center;
    height: 100%;
    padding: 1.5em;

    a {
      border: none;
      height: 2em;
      color: #fff;
    }

    &::last-of-type {
      padding-right: 0;
    }
  }
}
