html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
  line-height: 1.5;
  padding: 2em 2em;
  font-family: 'franklin-gothic-urw', Helvetica, sans-serif;
  overflow-x: hidden;
  transition: 300ms background-color ease-in-out, 300ms color ease-in-out;

  @media all and (min-width: 800px) {
    padding: 2em 10%;
    padding-bottom: 0;
  }
}

.font-sec {
  font-family: 'bely', serif;
}

.intro-section__bio {
  display: flex;

  > p {
    margin-top: 0;
  }
}

.intro-section__about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 800px) {
    flex-flow: row-reverse;
    flex-wrap: nowrap;
  }
}

.intro-section__text {
  padding-top: 2em;

  @media screen and (min-width: 800px) {
    padding-right: 2em;
    padding-top: 0;
    width: 70%;
  }
}

.intro-section__about img {
  max-width: 100%;
}

.dark {
  background-color: #111;
  color: #ccc;
}
