ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.horizontal-links li {
  display: inline-block;
  margin-right: 1em;

  &::last-child {
    margin-right: 0;
  }
}

.horizontal-links a {
  border: none;
}

.horizontal-links img {
  width: 1em;
  height: 1em;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}
